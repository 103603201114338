import { fetch } from "../utils/dataAccess";
import nameToUrls from "./nameToUrls";

import _ from "lodash";

const initialState = {
  error: null,
  loading: false,
  retrieved: null,
};
export const currentJobModel = (name = "currentJob") => {
  const data = {
    name: name,
    state: {
      actualJob: null,
    },
    reducers: {
      setActualJob(state, job) {
        return Object.assign({}, { ...state, actualJob: job });
      },
      emptyActualJob(state) {
        return Object.assign({}, { ...state, actualJob: null });
      },
    },
    effects: (dispatch) => ({
      async updateActualJob(payload, state) {
        return new Promise((resolve, reject) => {
          fetch("jobs/" + payload)
            .then((response) => response.json())
            .then((retrieved) => {
              this.setActualJob(retrieved);
            })
            .catch((e) => {});
        });
      },
    }),
  };
  return data;
};
