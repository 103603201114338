import { Toast, ToastBody, ToastHeader } from "reactstrap";
import React, { useEffect } from "react";
import { connect } from "react-redux";

const Jobs = (props) => {
  const [visible, setVisible] = React.useState(true);
  // let interval=null

  const [interval, setInt] = React.useState(null);
  useEffect(() => {
    setInt(setInterval(() => props.updateActualJob(props.actualJob.id), 2000));
    // debugger;
    return () => clearInterval(interval);
  }, [props.actualJob.id]);

  useEffect(() => {
    // debugger;
    if (props.actualJob.status.toLowerCase() !== "working") {
      clearInterval(interval);
      setVisible(true);
    }
  }, [props.actualJob]);

  if (!props.actualJob?.id) return <></>;

  if (!visible) {
    return <></>;
  }

  const close = (
    <button
      type="button"
      onClick={() => {
        setVisible(false);
      }}
      className="ml-1 close"
    >
      <span>×</span>
    </button>
  );
  const redirect = () => {
    window.location.href = "../jobs/show/" + props.actualJob?.id;
  };

  return (
    <Toast className="job-toast ">
      <ToastHeader className="bg-primary" close={close}>
        Generando los txt
      </ToastHeader>
      <ToastBody onClick={redirect}>
        {props.actualJob?.status === "Working" ? (
          <p>Se están generando los txt con orden #{props.actualJob?.id}</p>
        ) : props.actualJob?.status === "Finalized Ok" ? (
          <>
            {" "}
            <p>Se generaron los txt #{props.actualJob?.id} </p>
            <a href={"../jobs/show/" + props.actualJob?.id}>ir a ver</a>
          </>
        ) : props.actualJob?.status === "Error" ? (
          <>
            <p>Falló la generacion de txt #{props.actualJob?.id} </p>
            <a href={"../jobs/show/" + props.actualJob?.id}>ir a ver</a>
          </>
        ) : (
          "Estamos trabajando en los txt"
        )}
      </ToastBody>
    </Toast>
  );
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentJob.actualJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActualJob: (job) => dispatch.currentJob.setActualJob(job),
  updateActualJob: (id) => dispatch.currentJob.updateActualJob(id),
  emptyActualJob: () => dispatch.currentJob.emptyActualJob(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
