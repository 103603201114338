import React from "react";
import * as Icon from "react-feather";
import { FormattedMessage } from "react-intl";

const navigationConfig = [
  {
    id: "franchise",
    title: <FormattedMessage id="menu.franchise" defaultMessage="Franquicia" />,
    type: "collapse",
    icon: <Icon.Award size={20} />,
    children: [
      {
        id: "entities.franchises",
        title: (
          <FormattedMessage id="menu.franchises" defaultMessage="Franchises" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/franchises/",
      },

      {
        id: "entities.economicGroup",
        title: (
          <FormattedMessage
            id="menu.economicGroup"
            defaultMessage="Economic Group"
          />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/economic-groups/",
      },
      {
        id: "entities.franchisees",
        title: (
          <FormattedMessage id="menu.franchisee" defaultMessage="Franchisee" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/franchisees/",
      },
      {
        id: "entities.retails",
        title: <FormattedMessage id="menu.retail" defaultMessage="Retail" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/retails/",
      },
      {
        id: "entities.services",
        title: (
          <FormattedMessage id="menu.services" defaultMessage="Servicios" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [],
        navLink: "/service/",
      },
    ],
  },
  {
    id: "providers",
    title: (
      <FormattedMessage id="menu.providers" defaultMessage="Proveedores" />
    ),
    type: "collapse",
    icon: <Icon.Truck size={20} />,
    children: [
      {
        id: "entities.brands",
        title: <FormattedMessage id="menu.brands" defaultMessage="Marcas" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/brands/",
      },
      {
        id: "entities.suppliers",
        title: (
          <FormattedMessage id="menu.supplier" defaultMessage="Supplier" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/suppliers/",
      },
      {
        id: "entities.warehouses",
        title: (
          <FormattedMessage id="menu.warehouses" defaultMessage="Warehouses" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/warehouses/",
      },
      {
        id: "entities.paymentPlatforms",
        title: (
          <FormattedMessage id="menu.paymentPlatforms" defaultMessage="paymentPlatforms" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/payment-platforms/",
      },
    ],
  },
  {
    id: "ecommerce",
    title: <FormattedMessage id="menu.ecommerce" defaultMessage="Ecommerce" />,
    type: "collapse",
    icon: <Icon.ShoppingCart size={20} />,
    children: [
      {
        id: "entities.orders",
        title: <FormattedMessage id="menu.orders" defaultMessage="Orders" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/orders/",
      },
      {
        id: "entities.banners",
        title: <FormattedMessage id="menu.banners" defaultMessage="Banners" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/banners/",
      },
      {
        id: "entities.products",
        title: (
          <FormattedMessage id="menu.products" defaultMessage="Productos" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/products/",
      },
      {
        id: "entities.productPresentation",
        title: (
          <FormattedMessage
            id="menu.productPresentation"
            defaultMessage="Presentaciones de Productos"
          />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/product-presentations/",
      },
      {
        id: "entities.productTag",
        title: (
          <FormattedMessage
            id="menu.productTag"
            defaultMessage="Etiquetas de Productos"
          />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/product-tags/",
      },
      {
        id: "entities.presentations",
        title: (
          <FormattedMessage
            id="menu.presentations"
            defaultMessage="Presentaciones"
          />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/presentations/",
      },
      {
        id: "entities.categories",
        title: (
          <FormattedMessage id="menu.categories" defaultMessage="Categorias" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/categories/",
      },
      {
        id: "entities.taxes",
        title: <FormattedMessage id="menu.taxes" defaultMessage="Taxes" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/taxes/",
      },
    ],
  },
  {
    id: "territory",
    title: <FormattedMessage id="menu.territory" defaultMessage="Territorio" />,
    type: "collapse",
    icon: <Icon.Map size={20} />,
    children: [
      {
        id: "entities.country",
        title: <FormattedMessage id="menu.country" defaultMessage="Country" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/countries/",
      },
      {
        id: "entities.state",
        title: <FormattedMessage id="menu.state" defaultMessage="State" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/states/",
      },
      {
        id: "entities.canton",
        title: <FormattedMessage id="menu.canton" defaultMessage="Canton" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [],
        navLink: "/cantons/",
      },
    ],
  },
  {
    id: "users",
    title: <FormattedMessage id="menu.users" defaultMessage="Usuarios" />,
    type: "collapse",
    icon: <Icon.Users size={20} />,
    children: [
      {
        id: "entities.userTypes",
        title: (
          <FormattedMessage
            id="menu.userTypes"
            defaultMessage="Tipos de Usuario"
          />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/user-types/",
      },
      {
        id: "entities.users",
        title: <FormattedMessage id="menu.users" defaultMessage="Usuarios" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/users/",
      },
      {
        id: "entities.roles",
        title: <FormattedMessage id="menu.roles" defaultMessage="Roles" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/roles/",
      },
      {
        id: "entities.actionGroup",
        title: (
          <FormattedMessage
            id="menu.actionGroup"
            defaultMessage="ActionGroups"
          />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/action-groups/",
      },
      {
        id: "entities.action",
        title: <FormattedMessage id="menu.action" defaultMessage="Actions" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/actions/",
      },
    ],
  },
  {
    id: "settings",
    title: (
      <FormattedMessage id="menu.settings" defaultMessage="Configuraciones" />
    ),
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "entities.languages",
        title: (
          <FormattedMessage id="menu.languages" defaultMessage="Idiomas" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/languages/",
      },
      {
        id: "entities.bannersTwo",
        title: (
          <FormattedMessage id="menu.bannersTwo" defaultMessage="BannersTwo" />
        ),
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [],
        navLink: "/bannersTwo/",
      },

      {
        id: "entities.basic",
        title: <FormattedMessage id="menu.basic" defaultMessage="Basic" />,
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [],
        navLink: "/basic/",
      },
    ],
  },
];

export default navigationConfig;
