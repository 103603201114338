import { fetch } from "../utils/dataAccess";
import nameToUrls from "./nameToUrls";
import _ from "lodash";

const initialState = {
  error: null,
  loading: false,
  retrieved: null,
};
export const makeLanguageModel = (name) => {
  const data = {
    name: name,
    state: {
      create: initialState,
      list: initialState,
      del: initialState,
      show: initialState,
      update: initialState,
      options: initialState,
    },
    reducers: {
      setCreateError(state, error) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, error } }
        );
      },
      setCreateLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, loading } }
        );
      },
      setCreated(state, retrieved) {
        return Object.assign(
          {},
          { ...state, create: { ...state.create, retrieved } }
        );
      },
      createReset(state) {
        return Object.assign({}, { ...state, create: initialState });
      },
      setListError(state, error) {
        return Object.assign({}, { ...state, list: { ...state.list, error } });
      },
      setListLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, list: { ...state.list, loading } }
        );
      },
      setListed(state, retrieved) {
        return Object.assign(
          {},
          { ...state, list: { ...state.list, retrieved } }
        );
      },
      listReset(state) {
        return Object.assign({}, { ...state, list: initialState });
      },
      setDeleteError(state, error) {
        return Object.assign({}, { ...state, del: { ...state.del, error } });
      },
      setDeleteLoading(state, loading) {
        return Object.assign({}, { ...state, del: { ...state.del, loading } });
      },
      setDeleted(state, retrieved) {
        return Object.assign(
          {},
          { ...state, del: { ...state.del, retrieved } }
        );
      },
      deleteReset(state) {
        return Object.assign({}, { ...state, del: initialState });
      },
      setShowError(state, error) {
        return Object.assign({}, { ...state, show: { ...state.show, error } });
      },
      setShowLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, show: { ...state.show, loading } }
        );
      },
      setShowed(state, retrieved) {
        return Object.assign(
          {},
          { ...state, show: { ...state.show, retrieved } }
        );
      },
      showReset(state) {
        return Object.assign({}, { ...state, show: initialState });
      },
      setUpdateError(state, error) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, error } }
        );
      },
      setUpdateLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, loading } }
        );
      },
      setUpdated(state, retrieved) {
        return Object.assign(
          {},
          { ...state, update: { ...state.update, retrieved } }
        );
      },
      updateReset(state) {
        return Object.assign({}, { ...state, update: initialState });
      },
      setOptionsError(state, error) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, error } }
        );
      },
      setOptionsLoading(state, loading) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, loading } }
        );
      },
      setOptions(state, retrieved) {
        return Object.assign(
          {},
          { ...state, options: { ...state.options, retrieved } }
        );
      },
      optionsReset(state) {
        return Object.assign({}, { ...state, options: initialState });
      },
    },
    effects: (dispatch) => ({
      list(payload, state) {
        return new Promise((resolve, reject) => {
          this.setListLoading(true);
          this.setListError(null);

          let url = nameToUrls[name];

          if (payload !== undefined && payload?.url !== undefined)
            url += payload.url;

          fetch(url + "?languagesId=" + state.sso.languagesId)
            .then((response) =>
              response.json().then((retrieved) => ({ retrieved }))
            )
            .then(({ retrieved }) => {
              this.setListLoading(false);
              this.setListed(retrieved);
              resolve(retrieved);
            })
            .catch((e) => {
              this.setListLoading(false);
              this.setListError(e.message);
              reject(e.message);
            });
        });
      },
      options(payload, state) {
        return new Promise((resolve, reject) => {
          this.setOptionsLoading(true);
          this.setOptionsError(null);
          const url =payload?.url?payload.url:''
          const endpoint = payload?.endpoint
            ? payload.endpoint
            : "options/" + state.sso.languagesId;
          // debugger;
          fetch(nameToUrls[name] + endpoint+url)
            .then((response) =>
              response.json().then((retrieved) => ({ retrieved }))
            )
            .then(({ retrieved }) => {
              this.setOptionsLoading(false);
              const sortedArray = _.sortBy(retrieved, ["label"]);
              this.setOptions(sortedArray);
              resolve(sortedArray);
            })
            .catch((e) => {
              this.setOptionsLoading(false);
              this.setOptionsError(e.message);
              reject(e.message);
            });
        });
      },
      show(payload, state) {
        return new Promise((resolve, reject) => {
          this.setShowLoading(true);
          this.setShowError(null);

          fetch(
            nameToUrls[name] +
              payload.id +
              (payload.allLanguages
                ? ""
                : "?languagesId=" + state.sso.languagesId)
          )
            .then((response) =>
              response.json().then((retrieved) => ({ retrieved }))
            )
            .then(({ retrieved }) => {
              this.setShowLoading(false);
              this.setShowed(retrieved);
              resolve(retrieved);
            })
            .catch((e) => {
              this.setShowLoading(false);
              this.setShowError(e.message);
              reject(e.message);
            });
        });
      },
      create(payload, state) {
        return new Promise((resolve, reject) => {
          this.setCreateLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);
          this.setCreateError(null);

          fetch(nameToUrls[name], {
            method: "POST",
            body: JSON.stringify(payload.values),
          })
            .then((response) => {
              this.setCreateLoading(false);
              response.json().then((retrieved) => {
                this.setCreated(retrieved);
                resolve(retrieved);
              });
            })
            .catch((e) => {
              this.setCreateLoading(false);
              this.setCreateError(e.message);
              reject(e.message);
            });
        });
      },
      update(payload, state) {
        return new Promise((resolve, reject) => {
          this.setUpdateLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);
          this.setUpdateError(null);

          fetch(nameToUrls[name] + payload.item.id, {
            method: "PUT",
            body: JSON.stringify(payload.values),
          })
            .then((response) => {
              this.setUpdateLoading(false);
              response.json().then((retrieved) => {
                this.setUpdated(retrieved);
                resolve(retrieved);
              });
            })
            .catch((e) => {
              if (payload.parentEntity)
              {dispatch[payload.parentEntity].setShowLoading(false);}
              this.setUpdateLoading(false);
              this.setUpdateError(e.message);
              reject(e.message);
            });
        });
      },
      async delete(payload, state) {
        return new Promise((resolve, reject) => {
          this.setDeleteLoading(true);
          if (payload.parentEntity)
            dispatch[payload.parentEntity].setShowLoading(true);
          this.setDeleteError(null);

          fetch(nameToUrls[name] + payload.item.id, {
            method: "DELETE",
          })
            .then((response) => {
              this.setDeleteLoading(false);
              this.setDeleted(payload.item);
              resolve(payload.item);
            })
            .catch((e) => {
              this.setDeleteLoading(false);
              this.setDeleteError(e.message);
              reject(e.message);
            });
        });
      },
    }),
  };
  return data;
};
