const nameToUrls = {
  bannersTwo: "bannersTwo/",
  bases: "bases/",
  basesProductPresentation: "bases-product-presentation/",
  brands: "brands/",
  canton: "cantons/",
  state: "states/",
  stateLanguages: "state-languages/",
  franchises: "franchises/",
  franchisesLanguages: "franchises-languages/",
  franchisesFiles: "franchises/upload-product-template/",
  faq: "faqs/",
  faqLanguages: "faq-languages/",
  formulas: "formulas/",
  formulaInstances: "formula-instances/",
  retailCalendar: "retail-calendars/",
  retailRules: "retail-rules/",
  country: "countries/",
  countryLanguages: "country-languages/",
  economicGroup: "economic-groups/",
  franchisee: "franchisees/",
  retail: "retails/",
  retailProductTags: "retail-product-tags/",
  allRetailProductTags: "retail-product-tags/",
  service: "service/",
  languages: "languages/",
  cantonLanguages: "canton-languages/",
  supplier: "suppliers/",
  presentation: "presentations/",
  presentationLanguages: "presentation-languages/",
  category: "categories/",
  categoryLanguages: "category-languages/",
  rules: "rules/",
  rulesFormulas: "rules-formulas/",
  userTypes: "user-types/",
  userTypesLanguages: "user-types-languages/",
  products: "products/",
  productsLanguages: "product-languages/",
  productProductTags: "product-product-tags/",
  allProductProductTags: "product-product-tags/",
  productPresentation: "product-presentations/",
  productPresentationLanguages: "product-presentation-languages/",
  productPresentationImages: "product-presentation-images/",
  productPresentationFiles: "product-presentation-files/",
  productTag: "product-tags/",
  relatedProducts: "related-products/",
  comboRelatedProducts: "combo-related-products/",
  user: "users/",
  userRetail: "user-retails/",
  userSupplier: "user-suppliers/",
  city: "cities/",
  neighbourhood: "neighbourhoods/",
  zones: "zones/",
  warehouse: "warehouses/",
  warehouseRetail: "warehouse-retails/",
  allWarehouseRetails: "warehouse-retails/",
  banners: "banners/",
  bannerLanguages: "banner-languages/",
  taxes: "taxes/",
  retailTaxes: "retail-taxes/",
  allRetailTaxes: "retail-taxes/",
  supplierTaxes: "supplier-taxes/",
  productsTaxes: "product-taxes/",
  roles: "roles/",
  action: "actions/",
  actionGroup: "action-groups/",
  rolesAction: "roles-actions/",
  orders: "orders/",
  ordersDistributor: "orders/cartlike",
  paymentPlatforms: "payment-platforms/",
  paymentPlatformsLanguages: "payment-platform-languages/",
  supplierPaymentPlatforms: "supplier-payment-platforms/",
  retailPurchaseType: "retail-purchase-type/",
  allRetailPurchaseTypes: "retail-purchase-type/",
  purchaseType: "purchase-types/",
  warehouseStock: "warehouse-stocks/",
  priceList: "price-lists/",
  imports: "imports/",
  supplierProductPresentation: "supplier-product-presentations/",
  importsColumns: "imports-columns/",
  surveys: "survey/",
  workflowGroup: "workflow-groups/",
  franchiseeBalance: "franchisee-balances/",
  workflowStep: "workflow-steps/",
  workflowStatus: "workflow-statuses/",
  workflowStepButtons: "workflow-step-buttons/",
  userWorkflowGroup: "user-workflow-groups/",
  routes: "routes/",
  retailSupplier: "retail-suppliers/",
  parameters: "parameters/",
  categoryGoals: "category-goals/",
  events: "events/",
  saleAreas: "sales-areas/",
  saleAreasRetailProduct: "sales-areas-retail-products/",
  retailSaleAreasRetailProduct: "sales-areas/retail/",
  productSaleAreasRetailProduct: "sales-areas/product/",
  jobs: "jobs/",
  outCartAudits: "product-out-cart-audits/",
  predictions: "predictions/",
  timezone: "utc-zones/",
  warehouseStockAudit: "warehouse-stocks/audit",
  integrationSchedules: "integration-schedules/",
  appliedRules: "report/log-verificated-rules",
  productsByRetail: "report/product-presentations",
  floatingBands: "floating-bands/",

  floatingBandsCategorias: "floating-band-categories/",
};
export default nameToUrls;
