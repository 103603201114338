import React from "react";
import JobsToast from "./TaskJobsToast";
import { connect } from "react-redux";

const TaskJobs = (props) => {
  if (!props.actualJob?.id) return <></>;

  return <JobsToast />;
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentTaskJob.actualJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActualJob: (job) => dispatch.currentTaskJob.setActualJob(job),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskJobs);
