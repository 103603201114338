import React from "react";
import JobsToast from "./JobsToast";
import { connect } from "react-redux";

const Jobs = (props) => {
  if (!props.actualJob?.id) return <></>;

  return <JobsToast />;
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentJob.actualJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActualJob: (job) => dispatch.currentJob.setActualJob(job),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
