import { makeModel } from "./crudModel";
import { makeLanguageModel } from "./crudModelLanguages";
import { currentJobModel as currentJobModels } from "./currentJobModel";
import { makeRetailModel } from "./retailModel";
export const bannersTwo = makeModel("bannersTwo");
export const brands = makeLanguageModel("brands");
export const service = makeModel("service");
export const canton = makeLanguageModel("canton");
export const cantonLanguages = makeModel("cantonLanguages");
export const languages = makeModel("languages");
export const state = makeLanguageModel("state");
export const stateLanguages = makeModel("stateLanguages");
export const franchises = makeLanguageModel("franchises");
export const franchisesLanguages = makeModel("franchisesLanguages");
export const franchisesFiles = makeModel("franchisesFiles");
export const faq = makeLanguageModel("faq");
export const faqLanguages = makeModel("faqLanguages");
export const retailCalendar = makeModel("retailCalendar");
export const retailRules = makeModel("retailRules");
export const rules = makeLanguageModel("rules");
export const rulesFormulas = makeModel("rulesFormulas");
export const country = makeLanguageModel("country");
export const countryLanguages = makeModel("countryLanguages");
export const economicGroup = makeModel("economicGroup");
export const priceList = makeModel("priceList");
export const franchisee = makeModel("franchisee");
export const retail = makeRetailModel("retail");
export const retailProductTags = makeModel("retailProductTags");
export const allRetailProductTags = makeModel("allRetailProductTags");
export const supplierProductPresentation = makeModel(
  "supplierProductPresentation"
);
export const supplier = makeModel("supplier");
export const formulas = makeModel("formulas");
export const formulaInstances = makeModel("formulaInstances");
export const presentation = makeLanguageModel("presentation");
export const presentationLanguages = makeModel("presentationLanguages");
export const category = makeLanguageModel("category");
export const categoryLanguages = makeModel("categoryLanguages");
export const userTypes = makeLanguageModel("userTypes");
export const userTypesLanguages = makeModel("userTypesLanguages");
export const products = makeLanguageModel("products");
export const productsLanguages = makeModel("productsLanguages");
export const productProductTags = makeModel("productProductTags");
export const allProductProductTags = makeModel("allProductProductTags");
export const relatedProducts = makeModel("relatedProducts");
export const comboRelatedProducts = makeModel("comboRelatedProducts");
export const productPresentation = makeLanguageModel("productPresentation");
export const productPresentationLanguages = makeModel(
  "productPresentationLanguages"
);
export const productPresentationImages = makeModel("productPresentationImages");
export const productPresentationFiles = makeModel("productPresentationFiles");
export const user = makeLanguageModel("user");
export const userRetail = makeModel("userRetail");
export const userSupplier = makeModel("userSupplier");
export const city = makeLanguageModel("city");
export const neighbourhood = makeLanguageModel("neighbourhood");
export const zones = makeLanguageModel("zones");
export const warehouse = makeLanguageModel("warehouse");
export const warehouseRetail = makeModel("warehouseRetail");
export const allWarehouseRetails = makeModel("allWarehouseRetails");
export const productTag = makeModel("productTag");
export const saleAreas = makeModel("saleAreas");
export const saleAreasRetailProduct = makeModel("saleAreasRetailProduct");
export const retailSaleAreasRetailProduct = makeModel(
  "retailSaleAreasRetailProduct"
);
export const productSaleAreasRetailProduct = makeModel(
  "productSaleAreasRetailProduct"
);
export const banners = makeLanguageModel("banners");
export const bases = makeLanguageModel("bases");
export const basesProductPresentation = makeLanguageModel(
  "basesProductPresentation"
);
export const bannerLanguages = makeModel("bannerLanguages");
export const taxes = makeModel("taxes");
export const retailTaxes = makeModel("retailTaxes");
export const allRetailTaxes = makeModel("allRetailTaxes");
export const supplierTaxes = makeModel("supplierTaxes");
export const productsTaxes = makeModel("productsTaxes");
export const roles = makeModel("roles");
export const action = makeModel("action");
export const actionGroup = makeModel("actionGroup");
export const rolesAction = makeModel("rolesAction");
export const orders = makeModel("orders");
export const ordersDistributor = makeModel("ordersDistributor");
export const paymentPlatforms = makeLanguageModel("paymentPlatforms");
export const paymentPlatformsLanguages = makeModel("paymentPlatformsLanguages");
export const supplierPaymentPlatforms = makeModel("supplierPaymentPlatforms");
export const purchaseType = makeModel("purchaseType");
export const retailPurchaseType = makeModel("retailPurchaseType");
export const allRetailPurchaseTypes = makeModel("allRetailPurchaseTypes");
export const warehouseStock = makeLanguageModel("warehouseStock");
export const imports = makeModel("imports");
export const importsColumns = makeModel("importsColumns");
export const surveys = makeModel("surveys");
export const franchiseeBalance = makeModel("franchiseeBalance");
export const workflowGroup = makeModel("workflowGroup");
export const workflowStep = makeModel("workflowStep");
export const workflowStatus = makeModel("workflowStatus");
export const workflowStepButtons = makeModel("workflowStepButtons");
export const userWorkflowGroup = makeModel("userWorkflowGroup");
export const routes = makeModel("routes");
export const retailSupplier = makeModel("retailSupplier");
export const parameters = makeModel("parameters");
export const categoryGoals = makeModel("categoryGoals");
export const events = makeModel("events");
export const jobs = makeModel("jobs");
export const currentJobModel = currentJobModels("currentJob");
export const currentTaskJobModel = currentJobModels("currentTaskJob");
export const outCartAudits = makeModel("outCartAudits");
export const predictions = makeModel("predictions");
export const timezone = makeModel("timezone");
export const warehouseStockAudit = makeModel("warehouseStockAudit");
export const integrationSchedules = makeModel("integrationSchedules");
export const appliedRules = makeModel("appliedRules");
export const productsByRetail = makeModel("productsByRetail");
export const floatingBands = makeModel("floatingBands");
export const floatingBandsCategorias = makeModel("floatingBandsCategorias");
export { default as sso } from "./ssoModel";
