import { Toast, ToastBody, ToastHeader } from "reactstrap";
import React, { useEffect } from "react";
import { connect } from "react-redux";

const TaskJobs = (props) => {
  const [visible, setVisible] = React.useState(true);
  // let interval=null

  const [interval, setInt] = React.useState(null);
  useEffect(() => {
    setInt(setInterval(() => props.updateActualJob(props.actualJob.id), 2000));
    // debugger;
    return () => clearInterval(interval);
  }, [props.actualJob.id]);

  useEffect(() => {
    // debugger;
    if (props.actualJob.status.toLowerCase() !== "working") {
      clearInterval(interval);
      setVisible(true);
    }
  }, [props.actualJob]);

  if (!props.actualJob?.id) return <></>;

  if (!visible) {
    return <></>;
  }

  const close = (
    <button
      type="button"
      onClick={() => {
        setVisible(false);
      }}
      className="ml-1 close"
    >
      <span>×</span>
    </button>
  );

  return (
    <Toast className="job-toast-task ">
      <ToastHeader
        className={
          "job-toast-task-header " +
          (props.actualJob?.status === "Finalized Ok"
            ? "bg-success"
            : props.actualJob?.status === "Error"
            ? "bg-danger"
            : "bg-warning")
        }
        close={close}
      >
        Procesando las ordenes <small>tarea: #{props.actualJob?.id}</small>
      </ToastHeader>
      <ToastBody>
        {props.actualJob?.status === "Working" ? (
          <p>Se están procesando {props.actualJob?.data.length} ordenes</p>
        ) : props.actualJob?.status === "Finalized Ok" ? (
          <>
            <p>Se procesaron correctamente </p>
            <a href={"../tasklist/"}>Ir a mis tareas</a>
          </>
        ) : props.actualJob?.status === "Error" ? (
          <>
            <p>Falló el proceso #{props.actualJob?.id} </p>
          </>
        ) : (
          "Estamos procesando las ordenes"
        )}
      </ToastBody>
    </Toast>
  );
};

const mapStateToProps = (state) => {
  return {
    actualJob: state.currentTaskJob.actualJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActualJob: (job) => dispatch.currentTaskJob.setActualJob(job),
  updateActualJob: (id) => dispatch.currentTaskJob.updateActualJob(id),
  emptyActualJob: () => dispatch.currentTaskJob.emptyActualJob(),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskJobs);
